<template>
  <div>
    <Header />
      <div class="bg-pattern-opaque bg-center">
      <div class="bg-gradient-to-b from-transparent to-gray-100">
        <main class="px-8 py-5 md:py-15 max-w-6xl mx-auto">
          <!-- <img src="@/assets/NG-logo-trans.png" class="w-64 mx-auto h-auto" /> -->
          <h1 class="text-black mt-4 font-bold text-base text-center" style="font-size: xx-large;font-family: Poppins;"> Love Numbers Game or get your money back. </h1>
          <h2 class="text-gray-600 text-base text-center mt-5 md:mt-10 italic">Make better bets in seconds with our MatchView™ data cards & AI predictions.</h2>
          <h2 v-if="!subscriptionType" class="text-gray-600 text-base text-center mt-2 italic ">Subscribe to access or <span class="text-blue-700 mt-5 underline text-center justify-self-center cursor-pointer" v-on:click="getStartedButtonOnClick('free')">try the Premier League Games for free</span>.</h2>
          <div class="w-3/4 max-w-[320px] md:w-1/2 mt-5 md:mt-10 block m-auto">
            <div class="flex text-center border border-black/30 rounded-2xl bg-white shadow-lg cursor-pointer">
              <div @click="changePaymentInterval('monthly')" class="flex-1 p-2"
              :class="{'bg-blue-700 text-white shadow-lg rounded-2xl h-full': isMonthly, 'text-brandGrey h-full': !isMonthly}">Monthly</div>
              <div @click="changePaymentInterval('annual')" class="flex-1 p-2"
              :class="{'bg-blue-700 text-white shadow-lg rounded-2xl h-full': !isMonthly, 'text-brandGrey h-full': isMonthly}">Annual</div>
            </div>
          </div>
          <div class="flex flex-col gap-8 lg:flex-row m-5">
            <div
              class="max-w-[540px] w-11/12 flex-1 bg-white/50 hover:bg-white/80 border shadow-lg mx-auto border-white text-center mt-5 relative"
              v-if="subscriptionType != 'casual'"
            >
              <h2 class="bg-brandGrey/20 py-4 mb-4 uppercase font-semibold">Casual</h2>
              <ul class="w-full text-left px-4 text-sm font-medium pt-2">
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Access to Europe's top 5 domestic leagues</span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Access to the Numbers Game Discord community</span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg>
                  <span class="flex-1">1 daily football pick from our in-house tipster
                  </span>
                </li>
              </ul>
              <div class="p-4 mx-4 pt-1">
                <div
                  class="flex space-x-2 my-4 text-center"
                >
                  <div class="flex-1">
                    <div
                      class="flex-1 relative p-2 flex items-center justify-center text-md text-brandGrey"
                    >
                      <div class="flex-1">
                        <span v-if="isMonthly">£3.99 / month</span>
                        <span v-else>£28.99 / year</span>
                      </div>
                    </div>
                  </div>
                </div>
        
                <template v-if="subscriptionType == 'inactive'">
                  <template v-if="!updating">
                    <button
                      type="submit"
                      v-on:click="updateUser('casual')"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                    >
                      Upgrade Account
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                      <div class="text-xs text-brandGrey py-3 hidden" id="hidden-upgrade-not-working-button">
                      Not working? Click <a id="hidden-upgrade-not-working-text" href="" class="text-blue-700 underline">here</a>.
                    </div>
                    </button>
                  </template>
                  <template v-else>
                    <button
                      v-if="!testingSignUp"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                    >
                      Updating...
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                    </button>
                    <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button1">
                      Not working? Click <a id="hidden-not-working-text1" href="" class="text-blue-700 underline">here</a>.
                    </div>
                  </template>
                </template>
                <template v-else>
                  <button
                    v-on:click="getStartedButtonOnClick('casual')"
                    class="relative inline-flex bg-blue-700 items-center justify-center border border-brandGrey/40 text-xs p-3 px-6 my-1 font-thin uppercase lg:text-lg text-white hover:underline"
                  >
                    Get Started
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-30"
                    ></div>
                  </button>
                </template>
              </div>
              </div>
            <div
              class="max-w-[540px] w-11/12 flex-1 bg-white/50 hover:bg-white/80 border shadow-lg mx-auto border-white text-center mt-5 relative"
            >
              <h2 class="bg-brandGrey/20 py-4 mb-4 uppercase font-semibold">Analyst</h2>
              <ul class="w-full text-left px-4 text-sm font-medium pt-2">
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg>
                    <span class="flex-1">Full access to over <strong><span class="text-blue-700 underline">50+ competitions</span></strong></span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Access to the Numbers Game Discord community</span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">1 daily football pick from our in-house tipster</span>
                </li>
                <!-- <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Join the affiliate program and earn recurring commission!</span>
                </li> -->
              </ul>
              <div class="p-4 mx-4 pt-1">
                <div
                  class="flex space-x-2 my-4 text-center"
                >
                  <div class="flex-1">
                    <div
                      class="flex-1 relative p-2 flex items-center justify-center text-md text-brandGrey"
                    >
                      <div class="flex-1">
                        <span v-if="isMonthly"> £12.99 / month</span>
                        <span v-else> £68.99 / year</span>
                      </div>
                    </div>
                  </div>
                </div>
        
                <template v-if="subscriptionType == 'inactive'">
                  <template v-if="!updating">
                    <button
                      type="submit"
                      v-on:click="updateUser('analyst')"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                    >
                      Upgrade Account
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                      <div class="text-xs text-brandGrey py-3 hidden" id="hidden-upgrade-not-working-button">
                      Not working? Click <a id="hidden-upgrade-not-working-text" href="" class="text-blue-700 underline">here</a>.
                    </div>
                    </button>
                  </template>
                  <template v-else>
                    <button
                      v-if="!testingSignUp"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                    >
                      Updating...
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                    </button>
                    <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button1">
                      Not working? Click <a id="hidden-not-working-text1" href="" class="text-blue-700 underline">here</a>.
                    </div>
                  </template>
                </template>
                <template v-else>
                  <button
                    v-on:click="getStartedButtonOnClick('analyst')"
                    class="relative inline-flex bg-blue-700 items-center justify-center border border-brandGrey/40 text-xs p-3 px-6 my-1 font-thin uppercase lg:text-lg text-white hover:underline"
                  >
                    Get Started
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-30"
                    ></div>
                  </button>
                </template>
              </div>
            </div>
            <div
              class="max-w-[540px] w-11/12 flex-1 bg-white/50 hover:bg-white/80 border border-[#B867FF] shadow-lg mx-auto text-center mt-5 relative"
            >
              <h2 class="bg-gradient-to-br from-[#B867FF] to-[#8158F2] text-white py-4 mb-4 uppercase font-semibold flex justify-center items-center gap-2">Tipster<img src="./assets/discord-logo-white.png" class="h-3"></h2>
              <span
                class="bg-green-400 text-xs rounded p-2 absolute -top-2 -right-2 shadow-lg"
                >Most Popular!</span
              >
              <ul class="w-full text-left px-4 text-sm font-medium pt-2">
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">All the benefits of the Analyst Package</span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Unlimited multi-sport tips (via discord) from our in-house tipster - <strong>70% hit rate</strong></span>
                </li>
                <li class="flex items-center justify-center space-x-3 py-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg><span class="flex-1">Free monthly competitions</span>
                </li>
              </ul>
              <div class="p-4 mx-4 pt-1">
                <div
                  class="flex space-x-2 my-4 text-center"
                >
                  <div class="flex-1">
                    <div
                      class="flex-1 relative p-2 flex items-center justify-center text-md text-brandGrey"
                    >
                      <div class="flex-1">
                        <span v-if="isMonthly"> £24.99 / month</span>
                        <span v-else> £149.99 / year</span>
                      </div>
                    </div>
                  </div>
                </div>
        
                <template v-if="subscriptionType == 'inactive'">
                  <template v-if="!updating">
                    <button
                      type="submit"
                      v-on:click="updateUser('tipster')"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                    >
                      Upgrade Account
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                      <div class="text-xs text-brandGrey py-3 hidden" id="hidden-upgrade-not-working-button">
                      Not working? Click <a id="hidden-upgrade-not-working-text" href="" class="text-blue-700 underline">here</a>.
                    </div>
                    </button>
                  </template>
                  <template v-else>
                    <button
                      v-if="!testingSignUp"
                      class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                    >
                      Updating...
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                    </button>
                    <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button1">
                      Not working? Click <a id="hidden-not-working-text1" href="" class="text-blue-700 underline">here</a>.
                    </div>
                  </template>
                </template>
                <template v-else>
                  <button
                    v-on:click="getStartedButtonOnClick('tipster')"
                    class="relative inline-flex bg-blue-700 items-center justify-center border border-brandGrey/40 text-xs p-3 px-6 my-1 font-thin uppercase lg:text-lg text-white hover:underline lg:sticky lg:bottom-[5px]"
                  >
                    Get Started
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-30"
                    ></div>
                </button>
                </template>
              </div>
              </div>
            </div>
          <div
            class="fixed w-screen h-screen bg-black bg-opacity-70 inset-0 z-20 flex items-center justify-center"
            v-if="showForm"
            v-on:click.self="toggleForm"
          >
            <form
              class="space-y-4 text-left bg-white w-full max-w-lg p-6"
              data-rewardful
            >
              <img src="@/assets/NG-logo.jpg" class="w-64 mx-auto h-auto" />
              <p class="text-center uppercase text-black text-sm">
                Subscription - {{ getPriceOfPackage }}
              </p>
              <label class="block">
                <span class="block mb-2">email</span>
                <input
                  class="border border-black p-4 w-full"
                  type="email"
                  v-model="email"
                  :disabled="testingSignUp"
                />
              </label>
              <label class="block">
                <span class="block mb-2">password</span>
                <input
                  class="border border-black p-4 w-full"
                  type="password"
                  v-model="password"
                  :disabled="testingSignUp"
                />
              </label>
              <p class="text-sm my-4 text-red-600 text-left" v-if="error">
                {{ error }}
              </p>
              <p class="text-xs text-left text-gray-600">
                <label
                  for="terms"
                  class="items-center flex justify-center space-x-2"
                >
                  <input
                    type="checkbox"
                    id="terms"
                    v-model="termsAccepted"
                    :disabled="testingSignUp"
                  />
                  <span class="flex-1"
                    >I have read and accepted the
                    <a
                      href="/disclaimer#termsandconditions"
                      class="underline"
                      target="_blank"
                      >terms and conditions</a
                    >.
                  </span>
                </label>
              </p>
              <div class="text-center">
                <template v-if="!submitting">
                  <button
                    type="submit"
                    v-on:click="testSignUp"
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                  >
                    {{ testingSignUp ? "Signing up..." : "Sign up" }}
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                  <div class="text-xs text-brandGrey py-3 hidden" id="hidden-signup-not-working-button">
                      Not working? Click <a id="hidden-signup-not-working-text" href="" class="text-blue-700 underline">here</a>.
                    </div>
                </template>
                <template v-else>
                  <button
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                  >
                    Creating account...
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                </template>
                <!-- <template v-if="!updating && !testingSignUp">
                  <button
                    type="submit"
                    v-on:click="updateUser"
                    v-if="subscriptionType == 'inactive'"
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                  >
                    Upgrade Account
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                </template> -->
                <template v-if="updating">
                  <button
                    v-if="!testingSignUp"
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                  >
                    Updating...
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                    <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button2">
                      Not working? Click <a id="hidden-not-working-text2" href="" class="text-blue-700 underline">here</a>.
                    </div>
                  </button>
                </template>
              </div>
            </form>
          </div>
          <div class="w-full inset-0">
            <section class=" mx-auto py-10 relative">
              <div class="px-5 rounded-2xl">
                <div class="mb-4 md:mb-0 text-center">
                  <h2
                    class="uppercase text-blue-700 mb-4 font-bold text-2xl drop-shadow-lg"
                  >
                    Here's What Our Customers think👇🏻
                  </h2>
                </div>
                <span class="flex justify-center">
                  <span class="flex-col md:flex-row ">
                    <a class="cursor-pointer hidden md:block" href="https://uk.trustpilot.com/review/numbersgame.uk"> <img class="m-auto px-5 w-3/4" src="@/assets/Trustpilot-ng.png" /></a>
                    <a class="cursor-pointer md:hidden" href="https://uk.trustpilot.com/review/numbersgame.uk"> <img class="m-auto px-5 w-3/4 max-w-[330px]" src="@/assets/Mobile-trustpilot.png" /></a>
                    <span class="flex-col md:flex-row gap-1 flex">
                    <img class="m-auto w-[93%] md:w-1/3" src="@/assets/Review4.png" />
                    <img class="m-auto w-[98%] md:w-1/3" src="@/assets/Review2.png" />
                    <img class="m-auto w-[99%] md:w-1/3" src="@/assets/Review3.png" />
                  </span>
                    <!-- <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/Review2.png" />
                    <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/Review3.png" />            -->
                  </span >
                </span>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import API from "./api";
// import LeagueListFree from "./components/LeagueListFree.vue";
// import LeagueList from "./components/LeagueList.vue";

import Vue from "vue";
import VueCookies from "vue-cookies";
// import get from "lodash/get";
Vue.use(VueCookies, { expires: "7d" });

export default {
  name: "Signup",
  components: {
    Header,
    Footer,
    // LeagueList,
    // LeagueListFree
  },
  props: ["storeUTMValues"],
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      submitting: false,
      updating: false,
      termsAccepted: false,
      error: false,
      showForm: false,
      testingSignUp: false,
      isMonthly: true,
      selectedTips: true,
      isChecked: false,
      priceOfPackage: "free",
    };
  },
  mounted() {
    console.log(this.$store.getters.stripeProductCodes);
    if (
      this.type == "free" ||
      this.type == "annual" ||
      this.type == "monthly"
    ) {
      console.log("Correct payment type detected");
    } else {
      console.log("Incorrect payment type, reverting to monthly");
      this.$router.push("/signup/monthly");
    }

    if (this.subscriptionType == "active") {
      this.$router.push("/predictions");
    }
    if (this.subscriptionType == "inactive" && this.type == "free") {
      this.$router.push("/signup");
    }
    if (this.$route.query.showPopup) {
      this.toggleForm();
    }

    // tiktok tracking
    // eslint-disable-next-line no-undef
    ttq.track("ViewContent", {
      value: "1", // number. Value of the order or items sold. Example: 100.
      currency: "GBP", // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: "1", // string. ID of the product. Example: "1077218".
          content_type: "product", // string. Either product or product_group.
          content_name: "signup", // string. The name of the page or product. Example: "shirt".
          price: "subscription", // number. The price of a single item. Example: 25.
        },
      ],
    });
  },

  computed: {
    stripeProductCodes(){
      return this.$store.getters.stripeProductCodes;
    },
    stripeProductCode() {
      return this.$store.getters.stripeProductCode;
    },
    type() {
      return this.$route.params.type;
    },
    subscriptionType() {
      return this.$store.getters.subscriptionType;
    },
    getPriceOfPackage() {
      return this.priceOfPackage;
    },    
    nextMonth() {
      return "06/08/2022";
    },
    
    isAmericas() {
      return false;
      // return this.$store.getters.isAmericas;
    },
  },
  methods: {
    getStartedButtonOnClick(packageType){
      this.$store.state.stripeProductCode = this.getPackageTypePriceId(packageType);
      console.log(this.$store.state.stripeProductCode);
      if (!this.$store.state.stripeProductCode) {
        console.log("toggling free form");
        this.toggleFreeForm();
      } else {
        this.toggleForm();
      }
    },    
    getPackageTypePriceId(packageType){
      const stripeCodes = {
        monthly: {
          analyst: "price_1O4LAaDBCNN8YneAL2a3fMKD",
          tipster: "price_1QKfwqDBCNN8YneAlXZsqEu5",
          casual: "price_1QSflYDBCNN8YneA2wbdTeNW",
        },
        annual: {
          analyst: "price_1O4LBKDBCNN8YneApuQxrJP4",
          tipster: "price_1QKfy1DBCNN8YneA7yJ9tJiN",
          casual: "price_1QSfmmDBCNN8YneA4ei7SBC5",
        },
      };
      var interval = this.isMonthly ? "monthly" : "annual";
      if (packageType == "tipster") {
        if (interval == "monthly") {
          this.priceOfPackage = "£24.99 / month";
          return stripeCodes.monthly.tipster;
        } else {
          this.priceOfPackage = "£149.99 / year";
          return stripeCodes.annual.tipster;
        }
      } else if (packageType == "analyst") {
        if (interval == "monthly") {
          this.priceOfPackage = "£12.99 / month";
          return stripeCodes.monthly.analyst;
        } else {
          this.priceOfPackage = "£68.99 / year";
          return stripeCodes.annual.analyst;
        }
      } else if (packageType == "casual") {
        if (interval == "monthly") {
          this.priceOfPackage = "£3.99 / month";
          return stripeCodes.monthly.casual;
        } else {
          this.priceOfPackage = "£28.99 / year";
          return stripeCodes.annual.casual;
        }
      }
      this.priceOfPackage = "FREE";
      return false;
    },
    changePaymentInterval(interval){
      this.isMonthly = !this.isMonthly;
      if (interval == "monthly") {
        if (this.$route.path == "/signup/annual") {
          this.$router.push("/signup/monthly");
        }
      } else {
        if (this.$route.path == "/signup/monthly") {
          this.$router.push("/signup/annual");
        }
      }
    },
    toggleForm() {
      if (this.testingSignUp) {
        return;
      }
      this.showForm = !this.showForm;
      if (!this.showForm) {
        if (this.type == "free") {
          this.$router.push("/signup/monthly");
        }
      }
    },
    toggleFreeForm() {
      if (this.$route.path != "/signup/free") {
        this.$router.push("/signup/free");
      }
      this.toggleForm();
    },
    testSignUp(event) {
      event.preventDefault();
      var passwordValidator = require("password-validator");
      var schema = new passwordValidator();

      schema.has().uppercase().has().digits(1);

      if (
        this.email &&
        this.password &&
        schema.validate(this.password) &&
        this.termsAccepted
      ) {
        this.testingSignUp = true;
        this.submitSignUp();
      } else {
        if (!this.email) {
          this.error = "Please enter a valid email and password";
        } else if (!schema.validate(this.password)) {
          this.error =
            "Passwords must contain upper and lower case, at least one number and 8+ characters.";
        } else if (!this.termsAccepted) {
          this.error = "Please accept the terms and conditions.";
        }
      }
    },
    async submitSignUp() {
      this.error = false;
      let data = {
        email: this.email,
        password: this.password,
        ...this.storeUTMValues,
      };
      if (this.type == "free" || this.stripeProductCode == false) {
        API.post("/user/signup", false, data)
          .then((response) => {
            this.$store.commit("storeUserID", response.data.user_id);

            this.$store.commit(
              "storeSubscriptionType",
              response.data.subscription
            );
            this.$store.commit(
              "storeCustomerID",
              response.data.stripe_customer_id
            );
            this.submitLogIn();
          })
          .catch((error) => {
            if (error.response.data) {
              this.testingSignUp = false;
              this.submitting = false;
              this.error = error.response.data.body
                ? error.response.data.body
                : error.response.data;
            }
          });
      } else {
        API.post("/user/signup", false, data)
          .then(() => {
            this.getStripeURL(this.$store.state.stripeProductCode);
          })
          .catch((error) => {
            if (error.response.data) {
              this.testingSignUp = false;
              this.submitting = false;
              this.error = error.response.data.body
                ? error.response.data.body
                : error.response.data;
            }
          });
      }
    },
    async submitLogIn() {
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post("/user/login", false, data)
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);
          window.location = window.location.origin + "/predictions";
          // this.submitting = false;
          console.log("Logged in");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data) {
            this.submitting = false;
            this.error = error.response.data
              ? error.response.data
              : error.response.data.body;
          }
        });
    },
    async getStripeURL() {
      // if (event) {
      //   event.preventDefault();
      // }
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post("/user/login", false, data)
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);
          let auth = {
            idToken: this.$store.getters.accessTokens["id_token"],
          };
          API.get("/user", auth)
            .then((response) => {
              let data = {
                priceId: this.$store.state.stripeProductCode || "price_1O4LAaDBCNN8YneAL2a3fMKD",
                customerId: response.data.stripe_customer_id,
                customerUrl: this.$route.path,
                email: this.email,
                ttclid: this.$cookies.get("ttclid"),
                ttp: this.$cookies.get("_ttp"),
                user: response.data,
                useragent: navigator.userAgent,
                clientReferenceId:
                  (window.FPROM && window.FPROM.data.tid) ||
                  "fp_" + new Date().getTime(), //used for firstpromoter
              };
              API.post("/user/stripe_session", false, data)
                .then((response) => {
                  // eslint-disable-next-line no-undef
                  gtag("event", "begin_checkout", {
                    event_callback: function () {
                      window.location = response.data.url;
                      setTimeout(function (){
                        if (document.getElementById("hidden-not-working-button1") != null) {
                          document.getElementById("hidden-not-working-button1").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text1").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-not-working-button2") != null) {
                          document.getElementById("hidden-not-working-button2").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text2").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-signup-not-working-button") != null) {
                          document.getElementById("hidden-signup-not-working-button").classList.remove("hidden");
                          document.getElementById("hidden-signup-not-working-text").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-upgrade-not-working-button") != null) {
                          document.getElementById("hidden-upgrade-not-working-button").classList.remove("hidden");
                          document.getElementById("hidden-upgrade-not-working-text").href = response.data.url;  
                        }
                         
                      }, 1000);
                    },
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.error = error.response.data
              ? error.response.data
              : error.response.data.body;
          }
        });
    },
    async updateUser(packageType) {
      this.$store.state.stripeProductCode = this.getPackageTypePriceId(packageType);
      console.log(this.stripeProductCode);
      this.updating = true;
      if (event) {
        event.preventDefault();
      }
      let auth = {
        idToken: this.$store.getters.accessTokens["id_token"],
      };

      API.get("/user", auth)
        .then((response) => {
          let data = {
            priceId: this.$store.state.stripeProductCode,
            customerId: response.data.stripe_customer_id,
            customerUrl: this.$route.path,
            email: this.email,
            ttclid: this.$cookies.get("ttclid"),
            ttp: this.$cookies.get("_ttp"),
            user: response.data,
            useragent: navigator.userAgent,
            clientReferenceId:
              (window.FPROM && window.FPROM.data.tid) ||
              "fp_" + new Date().getTime(), //used for firstpromoter
          };
          API.post("/user/stripe_session", false, data)
            .then((response) => {
              // eslint-disable-next-line no-undef
              gtag("event", "begin_checkout", {
                event_callback: function () {
                  window.location = response.data.url;
                  setTimeout(function (){
                    if (document.getElementById("hidden-not-working-button1") != null) {
                          document.getElementById("hidden-not-working-button1").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text1").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-not-working-button2") != null) {
                      document.getElementById("hidden-not-working-button2").classList.remove("hidden");
                      document.getElementById("hidden-not-working-text2").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-signup-not-working-button") != null) {
                      document.getElementById("hidden-signup-not-working-button").classList.remove("hidden");
                      document.getElementById("hidden-signup-not-working-text").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-upgrade-not-working-button") != null) {
                      document.getElementById("hidden-upgrade-not-working-button").classList.remove("hidden");
                      document.getElementById("hidden-upgrade-not-working-text").href = response.data.url;  
                    }
                  }, 1000);
                }
              });
            })
            .catch((error) => {
              if (error.response.data) {
                this.submitting = false;
                this.updating = false;
                this.error = error.response.data.body;
              }
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.updating = false;
            this.error = error.response.data.body;
          }
        });
    },
  },
};
</script>

<style></style>
